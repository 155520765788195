import React from 'react';
import './styles/CollectionsToken.css';

const CollectionsToken = (props) => {
  return (
    <div className='token'>
      <div className='token_img-box'>
        <img className='token_image' src={props?.src} alt={props?.src} />
      </div>

      <p className='token_name'>{props?.amount} {props?.name}</p>
      {/* <p className='token_desc'>{props.desc1}</p> */}
      {/* <p className='token_desc'>{props.desc2}</p> */}
      <a href={props?.desc2} target='_blank' rel="noreferrer">
        <p className='nft_desc'>Etherscan</p>
      </a>
    </div>
  );
};

export default CollectionsToken;
