import React from 'react';
import { btn, btn_grey } from './Assets/Index';
import './styles/Button.css';

const Button = (props) => {
  let idName = props.name;
  if (typeof idName === 'undefined') {
    idName = 'img';
  }
  function makeDark(e) {
    document.getElementById(idName).style.opacity = '1';
  }
  function makeLight(e) {
    document.getElementById(idName).style.opacity = '0';
  }
  return (
    <div>
      {/* {console.log(props.onClick)} */}
      <button
        onClick={props.onClick}
        disabled={props.disabled}
        className='Button__hover'
        style={{
          position: 'relative',
          height: props.btnh,
          width: props.btnw,
          border: 'none',
          backgroundColor: 'rgba(52, 52, 52, 0.0)',
          textAlign: 'center',
          cursor: 'pointer',
        }}
        onMouseOver={makeDark}
        onMouseOut={makeLight}
      >
        <img
          src={btn}
          alt='btn'
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            height: props.btnh,
            width: props.btnw,
          }}
        />
        <img
          id={idName}
          src={btn_grey}
          alt='btn_grey'
          style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            opacity: '0',
            height: props.btnh,
            width: props.btnw,
          }}
        />

        <div
          style={{
            position: 'absolute',
            margin: 'auto',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            width: '100%',
            textAlign: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p
            style={{
              // top: props.txtt,
              // bottom: props.txtb,
              // left: props.txtl,
              // right: props.txtr,
              // height: 'max-content',
              // width: 'max-content',
              fontFamily: props.txtf,
              fontSize: props.txtsz,
              lineHeight: props.txtlh,
              textShadow: '0px 3.45503px 3.45503px rgba(0, 0, 0, 0.25)',
              marginBottom: '13%',
            }}
          >
            {props.txt}
          </p>
        </div>
      </button>
    </div>
  );
};

export default Button;
