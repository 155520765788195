import React from 'react';
// import { tokens_cong } from './Assets/Index';
import './styles/TokenBought.css';

const TokenBought = (props) => {
  console.log(props.children);
  return (
    <div
      className='token-bought'
      style={{ display: 'flex', flexDirection: 'column', opacity: props.opacity }}
    >
      <div className='token-bought_nft'>
        {/* <div className='token-bought_nft_img'>
          <img src={props.src} alt='nft' />
        </div> */}
         <div>
          <img src={props.src} alt='nft'className='token-bought_nft_img' />
        </div>
        <div className='token-bought_nft_name'>{props.name}</div>
        {/* <div className='token-bought_nft_name'>{props.description}</div> */}
        <a className='token-bought_nft_name' href={props.etherscan}  target="_blank" rel="noreferrer">Etherscan</a>
        <a className='token-bought_nft_name' href={props.opensea}  target="_blank" rel="noreferrer">Opensea</a>
      </div>
      {/* <div className='token-bought_tokens'>
        <img src={tokens_cong} alt='tokens_cong' />
      </div> */}
    </div>
  );
};

export default TokenBought;
