import React from 'react';
import { Link } from 'react-router-dom';
import { back_button, back_button_grey } from './Assets/Index';

const BackButton = (props) => {
  let idName = props.name;
  function makeDark(e) {
    document.getElementById(idName).style.opacity = '1';
  }
  function makeLight(e) {
    document.getElementById(idName).style.opacity = '0';
  }

  return (
    <Link
      to={props.link}
      state={props.state}
      style={{
        position: 'absolute',
        top: props.btnTp,
        left: props.btnBtm,
        height: props.btnh,
        width: props.btnw,
        border: 'none',
        backgroundColor: 'rgba(52, 52, 52, 0.0)',
        cursor: 'pointer',
      }}
      className='back-button'
      onMouseOver={makeDark}
      onMouseOut={makeLight}
      onClick={props.onClick}
    >
      <img
        style={{
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          height: '100%',
          width: '100%',
        }}
        src={back_button}
        alt='back_button'
      />
      <img
        style={{
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          opacity: '0',
          height: '100%',
          width: '100%',
        }}
        id={idName}
        src={back_button_grey}
        alt='back_button_grey'
      />
    </Link>
  );
};

export default BackButton;
