import React from 'react';
import { useLocation } from 'react-router-dom';
// import { nft_cong, start_cong } from './Assets/Index';
import { start_cong } from './Assets/Index';
import BackButton from './BackButton';
import './styles/CongPage.css';
import TokenBought from './TokenBought';
import { tokens_cong_2 } from './Assets/Index';
// import { tokens_cong } from './Assets/Index';
import './styles/TokenBought.css';

const StutiPage = () => {
  let tokenBought = 5;
  const location = useLocation();
  const metadata = location.state?.metadata;

  // console.log("2: ");
  // const state2 = useLocation();
  // console.log(state2);
  // const metadata2 = state2.state.metadata;
  // const cnt2 = state2.state.cnt;
  // console.log(cnt2);
  // console.log(metadata2);

  // const boughtTokensInfo = [
  // {
  //   tokenName: (
  //     <p>
  //       Ether scan
  //       <br /> Opensea
  //     </p>
  //   ),
  //   src: 'https://ipfs.io/ipfs/QmQYNuLLyWm4gwB7Ecza7qCQRubGDMCjqp32yYdUP4akcz/1.png',
  //   index: 1,
  //   name: "SRT Token#1",
  //   etherscan: "https://goerli.etherscan.io//tx/0xe36b0548782bf1b1e47e27830873094094db397cbabb172a426aa40c7151d12d",
  //   opensea: "https://testnets.opensea.io/assets/goerli/0xe9736892e271501269dBdfAEBE74132e28FC64e9/1"
  // },
  // {
  //   tokenName: (
  //     <p>
  //       Ether scan
  //       <br /> Opensea
  //     </p>
  //   ),
  //   src: 'https://ipfs.io/ipfs/QmQYNuLLyWm4gwB7Ecza7qCQRubGDMCjqp32yYdUP4akcz/2.png',
  //   index: 2,
  //   name: "SRT Token#2",
  //   etherscan: "https://goerli.etherscan.io//tx/0xe36b0548782bf1b1e47e27830873094094db397cbabb172a426aa40c7151d12d",
  //   opensea: "https://testnets.opensea.io/assets/goerli/0xe9736892e271501269dBdfAEBE74132e28FC64e9/2"

  // },
  // {
  //   tokenName: (
  //     <p>
  //       Ether scan
  //       <br /> Opensea
  //     </p>
  //   ),
  //   src: 'https://ipfs.io/ipfs/QmQYNuLLyWm4gwB7Ecza7qCQRubGDMCjqp32yYdUP4akcz/3.png',
  //   index: 3,
  //   name: "SRT Token#3",
  //   etherscan: "https://goerli.etherscan.io//tx/0xe36b0548782bf1b1e47e27830873094094db397cbabb172a426aa40c7151d12d",
  //   opensea: "https://testnets.opensea.io/assets/goerli/0xe9736892e271501269dBdfAEBE74132e28FC64e9/3"

  // },
  // {
  //   tokenName: (
  //     <p>
  //       Ether scan
  //       <br /> Opensea
  //     </p>
  //   ),
  //   src: 'https://ipfs.io/ipfs/QmQYNuLLyWm4gwB7Ecza7qCQRubGDMCjqp32yYdUP4akcz/4.png',
  //   index: 4,
  //   name: "SRT Token#4",
  //   etherscan: "https://goerli.etherscan.io//tx/0xe36b0548782bf1b1e47e27830873094094db397cbabb172a426aa40c7151d12d",
  //   opensea: "https://testnets.opensea.io/assets/goerli/0xe9736892e271501269dBdfAEBE74132e28FC64e9/4"

  // },
  // {
  //   tokenName: (
  //     <p>
  //       Ether scan
  //       <br /> Opensea
  //     </p>
  //   ),
  //   src: 'https://ipfs.io/ipfs/QmQYNuLLyWm4gwB7Ecza7qCQRubGDMCjqp32yYdUP4akcz/5.png',
  //   index: 5,
  //   name: "SRT Token#5",
  //   etherscan: "https://goerli.etherscan.io//tx/0xe36b0548782bf1b1e47e27830873094094db397cbabb172a426aa40c7151d12d",
  //   opensea: "https://testnets.opensea.io/assets/goerli/0xe9736892e271501269dBdfAEBE74132e28FC64e9/5"

  // },
  // ];

  
  
  return (
    <div className='cong-page'>
      <BackButton
        btnh='5%'
        btnw='2.5%'
        btnTp='2.5%'
        btnBtm='3.8%'
        name='mint_1'
        link='/mint-page'
        state={{ count: location.state?.count }}
      />
      <div className='cong-page_start'>
        <img src={start_cong} alt='start_cong' />
      </div>
      <div className='cong-page_bought'>
        {
          // boughtTokensInfo.map((info) => (
          //   <TokenBought
          //     tokenName={info.tokenName}
          //     src={info.src}
          //     key={info.tokenName}
          //     name={info.name}
          //     etherscan={info.etherscan}
          //     opensea={info.opensea}
          //     opacity={info.index <= tokenBought ? '1' : '0.2'}
          //   />
          // ))
          metadata.map((info) => (
            <TokenBought
              // tokenName={info.tokenName}
              src={info.image}
              key={info.name}
              name={info.name}
              etherscan={info.etherscan}
              opensea={info.opensea}
              opacity={info.index <= tokenBought ? '1' : '0.2'}
            />
          ))
        }
      </div>
      {/* <div className='token-bought_tokens'>
        <img src={tokens_cong_2} alt='tokens_cong' />
      </div> */}
      <div className='token-bought_tokens'>
        <img src={tokens_cong_2} alt='tokens_cong' />
      </div>
    </div>
  );
};

export default StutiPage;
