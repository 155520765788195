import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  collections_header,
  // nft_image_1,
  // nft_image_2,
  // nft_image_3,
  // nft_image_4,
  others_fortune,
  others_grid,
  others_indexx,
  others_skull_punks,
  others_sorekt,
  others_zombie_labs,
  token_image_1,
  token_image_2,
  token_image_3,
  token_image_4,
  token_image_5,
  token_image_6,
  token_plus,
} from "./Assets/Index";
import ERC20ABI from "./contractsData/ERC20ABI.json";

import CollectionsNavBar from "./CollectionsNavBar";
import CollectionsNft from "./CollectionsNft";
import CollectionsToken from "./CollectionsToken";
import "./styles/CollectionsPage.css";
import CONFIG from "./Config.json";
import { ethers } from "ethers";

const CollectionsPage = ({ account, soRekt, provider }) => {
  let content;
  const navigate = useNavigate();
  // console.log(account);
  // const nftData = [
  //   {
  //     src: nft_image_1,
  //     name: "NFT #1000",
  //     desc1: "Opensea",
  //     desc2: "Etherscan",
  //   },
  //   {
  //     src: nft_image_2,
  //     name: "NFT #089",
  //     desc1: "Opensea",
  //     desc2: "Etherscan",
  //   },
  //   {
  //     src: nft_image_3,
  //     name: "NFT #021",
  //     desc1: "Opensea",
  //     desc2: "Etherscan",
  //   },
  //   {
  //     src: nft_image_4,
  //     name: "NFT #012",
  //     desc1: "Opensea",
  //     desc2: "Etherscan",
  //   },
  //   {
  //     src: nft_image_1,
  //     name: "NFT #1001",
  //     desc1: "Opensea",
  //     desc2: "Etherscan",
  //   },
  //   {
  //     src: nft_image_2,
  //     name: "NFT #090",
  //     desc1: "Opensea",
  //     desc2: "Etherscan",
  //   },
  //   {
  //     src: nft_image_3,
  //     name: "NFT #022",
  //     desc1: "Opensea",
  //     desc2: "Etherscan",
  //   },
  //   {
  //     src: nft_image_4,
  //     name: "NFT #013",
  //     desc1: "Opensea",
  //     desc2: "Etherscan",
  //   },
  //   {
  //     src: nft_image_1,
  //     name: "NFT #1002",
  //     desc1: "Opensea",
  //     desc2: "Etherscan",
  //   },
  //   {
  //     src: nft_image_2,
  //     name: "NFT #091",
  //     desc1: "Opensea",
  //     desc2: "Etherscan",
  //   },
  //   {
  //     src: nft_image_3,
  //     name: "NFT #023",
  //     desc1: "Opensea",
  //     desc2: "Etherscan",
  //   },
  //   {
  //     src: nft_image_4,
  //     name: "NFT #014",
  //     desc1: "Opensea",
  //     desc2: "Etherscan",
  //   },
  // ];
  // testnet
  const network = CONFIG.NETWORK.NAME;
  const [tokenData, setTokenData] = useState([
    {
      src: token_image_1,
      amount: "102",
      name: "IN500",
      desc2:
        "https://goerli.etherscan.io/token/0xD1feBC83Bc784cE3F21986FcEBA08791072046A1?a=" +
        account,
      address: CONFIG.TOKEN_ADDRESS[network].IN500,
      // address: "0xD1feBC83Bc784cE3F21986FcEBA08791072046A1",
    },
    {
      src: token_image_2,
      amount: "1200",
      name: "INEX",
      desc2:
        "https://goerli.etherscan.io/token/0x674d7916e8B7ccD3Bc54eDf2a1274F683501413a?a=" +
        account,
        address: CONFIG.TOKEN_ADDRESS[network].INEX,
      // address: "0x674d7916e8B7ccD3Bc54eDf2a1274F683501413a",
    },
    {
      src: token_image_3,
      amount: "1200",
      name: "IUSD+",
      desc2:
        "https://goerli.etherscan.io/token/0x72ADA7cc2d933134010d6ccdd14512E6B0fd0761?a=" +
        account,
        address: CONFIG.TOKEN_ADDRESS[network].IUSD,
      // address: "0x72ADA7cc2d933134010d6ccdd14512E6B0fd0761",
    },
    {
      src: token_image_4,
      amount: "1200",
      name: "INXC",
      desc2:
        "https://goerli.etherscan.io/token/0x172aa1665048C95F1cD8586191dE02E84EC69079?a=" +
        account,
        address: CONFIG.TOKEN_ADDRESS[network].INXC,
      // address: "0x172aa1665048C95F1cD8586191dE02E84EC69079",
    },
    {
      src: token_image_5,
      amount: "1200",
      name: "INXP",
      desc2:
        "https://goerli.etherscan.io/token/0xd98f0397B65e3bD1F6433eB044242136B2C842a6?a=" +
        account,
        address: CONFIG.TOKEN_ADDRESS[network].INXP,
      // address: "0xd98f0397B65e3bD1F6433eB044242136B2C842a6",
    },
    {
      src: token_image_6,
      amount: "120",
      name: "SRT",
      desc2:
        "https://goerli.etherscan.io/token/0x61c7F8ce88855E5Cdb7138Fa0b1Df6947fC47b56?a=" +
        account,
        address: CONFIG.TOKEN_ADDRESS[network].SRT,
      // address: "0x61c7F8ce88855E5Cdb7138Fa0b1Df6947fC47b56",
    },
  ]);

  // const tokenData = [
  //   {
  //     src: token_image_1,
  //     amount:'102',
  //     name: 'IN500',
  //     // desc1: 'Opensea',
  //     desc2: 'Etherscan',
  //   },
  //   {
  //     src: token_image_2,
  //     name: '120 INEX',
  //     // desc1: 'Opensea',
  //     desc2: 'Etherscan',
  //   },
  //   {
  //     src: token_image_3,
  //     name: '1000 IUSD+',
  //     // desc1: 'Opensea',
  //     desc2: 'Etherscan',
  //   },
  //   {
  //     src: token_image_4,
  //     name: '120 INXC',
  //     // desc1: 'Opensea',
  //     desc2: 'Etherscan',
  //   },
  //   {
  //     src: token_image_5,
  //     name: '120 INXP',
  //     // desc1: 'Opensea',
  //     desc2: 'Etherscan',
  //   },
  //   {
  //     src: token_image_6,
  //     name: '120 SOREKT',
  //     // desc1: 'Opensea',
  //     desc2: 'Etherscan',
  //   },
  // ];
  const [NFTData, setNFTData] = useState([]);

  const [page, setPage] = useState("nft");
  const loadNFTData = async () => {
    let data = [];
    try {
      let listNFT = await soRekt.walletOfOwner(account);
      for (const key in listNFT) {
        let tokenId = parseInt(listNFT[key], 10);
        let tokenURI = await soRekt.tokenURI(tokenId);
        tokenURI = "https://ipfs.io/ipfs/" + tokenURI.substring(7);
       
        let imageUrl =
          "https://ipfs.io/ipfs/QmQYNuLLyWm4gwB7Ecza7qCQRubGDMCjqp32yYdUP4akcz/" +
          tokenId +
          ".png";
        const opensea =
          CONFIG.MARKETPLACE_LINK +
          "/" +
          CONFIG.CONTRACT_ADDRESS +
          "/" +
          tokenId;
        data.push({
          tokenId: tokenId,
          src: imageUrl,
          name: "SoRekt NFT #" + tokenId,
          desc1: opensea,
          desc2:
            "https://goerli.etherscan.io/token/0xe9736892e271501269dBdfAEBE74132e28FC64e9?a=" +
            tokenId,
        });
      }
      setNFTData(data);
    } catch (error) {}
  };

  const loadTokenData = async () => {
    if (!provider || !account) navigate("/");
    const signer = provider.getSigner();
    // let data = tokenData;
    let data = [];
    for (let index = 0; index < tokenData.length; index++) {
      const contractsData = new ethers.Contract(
        tokenData[index].address,
        ERC20ABI.abi,
        signer
      );
      let _bal = await contractsData.balanceOf(account);
      _bal = parseInt(_bal) / 10 ** 18;
      // data[index].amount = _bal;
      // data[index].desc2="https://goerli.etherscan.io/token/"+data[index].address+"/?a="+account;
      data.push({
        src: tokenData[index].src,
        amount: _bal,
        name: tokenData[index].name,
        desc2:
          "https://goerli.etherscan.io/token/" +
          tokenData[index].address +
          "/?a=" +
          account,
        address: tokenData[index].address,
      });
    }
    setTokenData(data);
  };
  function onClickNft() {
    setPage("nft");
    loadNFTData();
  }
  function onClickToken() {
    setPage("token");

    loadTokenData();
  }
  function onClickOthers() {
    setPage("others");
  }

  if (page === "nft") {
    content = (
      <div className="collections_nft">
        {/* {nftData.map((info) => ( */}
        {NFTData.map((info) => (
          <CollectionsNft
            src={info.src}
            key={info.name}
            name={info.name}
            desc1={info.desc1}
            desc2={info.desc2}
            tokenId={info.tokenId}
          />
        ))}
      </div>
    );
  } else if (page === "token") {

    // loadTokenData();
    content = (
      <div className="collections_token">
        {tokenData.map((info) => (
          <CollectionsToken
            src={info.src}
            key={info.name}
            amount={info.amount}
            name={info.name}
            // desc1={info.desc1}
            desc2={info.desc2}
          />
        ))}
        <div className="token_plus">
          <div className="token_plus_box">
            <button className="token_plus_btn">
              <img
                className="token_plus_img"
                src={token_plus}
                alt="token_plus"
              />
            </button>
          </div>
        </div>
        <div className="token_plus">
          <div className="token_plus_box">
            <button className="token_plus_btn">
              <img
                className="token_plus_img"
                src={token_plus}
                alt="token_plus"
              />
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <div className="others">
        <img className="others_img" src={others_grid} alt="others_image" />
        <a
          href="https://fortune.daily.indexx.ai/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="others_fortune"
            src={others_fortune}
            alt="others_fortune"
          />
        </a>

        <a href="https://indexx.ai/" target="_blank" rel="noreferrer">
          <img
            className="others_indexx"
            src={others_indexx}
            alt="others_indexx"
          />
        </a>

        <a href="https://sorekt.wtf/" target="_blank" rel="noreferrer">
          <img
            className="others_sorekt"
            src={others_sorekt}
            alt="others_sorekt"
          />
        </a>

        <a href="https://www.skullpunks.com/" target="_blank" rel="noreferrer">
          <img
            className="others_skull-punks"
            src={others_skull_punks}
            alt="others_skull_punks"
          />
        </a>
        <a href="https://www.zombielabs.wtf/" target="_blank" rel="noreferrer">
          <img
            className="others_zombie-labs"
            src={others_zombie_labs}
            alt="others_zombie_labs"
          />
        </a>
      </div>
    );
  }
  useEffect(() => {
    if (!account) {
      navigate("/");
    }
    if (page === "nft") loadNFTData();
    else if (page === "token") {

      loadTokenData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, account]);

  return (
    <div className="collections">
      <div className="collections_header">
        <img src={collections_header} alt="collections_header" />
      </div>
      <CollectionsNavBar
        onClickNft={onClickNft}
        onClickToken={onClickToken}
        onClickOthers={onClickOthers}
      />
      {content}
      <div className="collection_go-back">
        <NavLink to="/">
          <button className="collection_go-back_btn">Go Back</button>
        </NavLink>
      </div>
    </div>
  );
};

export default CollectionsPage;
