import React from 'react';
import {
  back_button,
  back_button_grey,
  dog_image,
  meta_mask_logo,
} from './Assets/Index';
import './styles/TilePopUp.css';
import Button from './Button';

const TilePopUp = (props) => {
  let backButtonIdName = props.backButtonIdName;
  function makeDark(e) {
    document.getElementById(backButtonIdName).style.opacity = '1';
  }
  function makeLight(e) {
    document.getElementById(backButtonIdName).style.opacity = '0';
  }

  let backButton;

  if (props.backButton) {
    backButton = (
      <div>
        <button
          style={{
            position: 'absolute',
            top: '120px',
            left: '100px',
            height: '40px',
            width: '40px',
            border: 'none',
            backgroundColor: 'rgba(52, 52, 52, 0.0)',
            cursor: 'pointer',
            // margin: 'auto',
          }}
          onMouseOver={makeDark}
          onMouseOut={makeLight}
          onClick={props.onBackButtonClick}
        >
          <img
            style={{
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: '0',
              right: '0',
              height: '100%',
              width: '100%',
            }}
            src={back_button}
            alt='back_button'
          />
          <img
            style={{
              position: 'absolute',
              top: '0',
              bottom: '0',
              left: '0',
              right: '0',
              opacity: '0',
              height: '100%',
              width: '100%',
            }}
            id={backButtonIdName}
            src={back_button_grey}
            alt='back_button_grey'
          />
        </button>
      </div>
    );
  } else {
    backButton = '';
  }

  return props.trigger ? (
    <div className='tile-pop'>
      <div className='tile-pop_inner'>
        {backButton}
        <div className='tile-pop_inner_content'>
          <img className='tile-pop_inner_img' src={dog_image} alt='dog_image' />
          <p className='tile-pop_inner_txt'>{props.text}</p>
          <div className='tile-pop_inner_btnimg'>
            {props.isIcon ? (
              <img src={meta_mask_logo} alt='meta_mask_logo' />
            ) : (
              <></>
            )}
            <Button
              onClick={props.onClick}
              txt={props.btnText}
              btnw={props.btnw}
              btnh={props.btnh}
              txtt={props.txtt}
              txtb={props.txtb}
              txtr={props.txtr}
              txtl={props.txtl}
              txtsz={props.txtsz}
              txtf={props.txtf}
              txtlh={props.txtlh}
              name={props.name}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

export default TilePopUp;
