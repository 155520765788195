import axios from "axios";
let baseAPIURL = '';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  baseAPIURL =  "https://test.api.indexx.ai";
//  baseAPIURL = "http://localhost:3000";
} else {
  baseAPIURL =  "https://api.indexx.ai"; 
}
//console.log("baseURL", baseURL);

const API = axios.create({
  baseURL: baseAPIURL,
});


export const verifyIsWhiteListed = async (address) => {
  try {
    const result = await API.post("/api/v1/srt/checkIsWhitelist", {
      address,
    });
    return result.data;
  } catch (e) {
    return e.response.data;
  }
};