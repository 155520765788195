import React from 'react';
import './styles/CollectionsNft.css';

const CollectionsNft = (props) => {
  return (
    <div className='nft'>
      <img className='nft_image' src={props?.src} alt="" />
      <p className='nft_name'>{props?.name}</p>
      <a href={props?.desc1} target='_blank' rel="noreferrer">
        <p className='nft_desc'>Opensea</p>
      </a>
      <a href={props?.desc2} target='_blank' rel="noreferrer">
        <p className='nft_desc'>Etherscan</p>
      </a>
    </div>
  );
};

export default CollectionsNft;
