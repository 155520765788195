import HomePage from './HomePage';
import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import {
  isChrome,
  isEdge,
  isFirefox,
  isIE,
  isOpera,
} from 'react-device-detect';

import MintPage from './MintPage';
import CongPage from './CongPage';
import { ethers } from 'ethers';

import './styles/App.css';
import SoRektAddress from './contractsData/SoRekt-address.json';
import SoRektAbi from './contractsData/SoRekt.json';
import TilePopUp from './TilePopUp';
import CollectionsPage from './CollectionsPage';
// const ethers = require('ethers');
function App() {
  const [provider, setProvider] = useState();
  const [account, setAccount] = useState(null);
  const [soRekt, setSoRekt] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [navigation, setNavigation] = useState('/');
  const [isWhitelisted, setWhitelisted] = useState();
  // const handleSwitchNetwork = async () => {
  //   console.log('in swtich net');
  //   try {
  //     let chainId = await window.ethereum.request({ method: 'eth_chainId' });
  //     console.log(chainId);
  //     chainId = parseInt(chainId.toString(), 16);
  //     console.log('Chain id: ', chainId);
  //     if (chainId !== 5) {
  //       // setIsModalOpen(true);
  //       await window.ethereum.request({
  //         method: 'wallet_addEthereumChain',
  //         params: [
  //           {
  //             chainId: '0x5',
  //             chainName: 'Goerli test network',
  //             rpcUrls: ['https://goerli.infura.io/v3/e3562069a1d44d18aa58a3ea55ccf21a'],
  //               nativeCurrency: {
  //                 symbol: 'ETH',
  //                 decimals: 18,
  //               },
  //             blockExplorerUrls: ['https://goerli.etherscan.io/'],
  //           },
  //         ],
  //       });
  //     }
  //     chainId = await window.ethereum.request({ method: 'eth_chainId' });
  //     chainId = parseInt(chainId.toString(), 16);
  //     console.log('Chain id: ', chainId);
  //     if (chainId !== 5) return 0;
  //   } catch (err) {
  //     console.log(err);
  //     return 0;
  //   }
  //   return 1;
  // };
  const findBrowserAndSetNavigation = () => {
    if (isFirefox)
      setNavigation(
        'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/'
      );
    else if (isEdge || isIE)
      setNavigation(
        'https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US'
      );
    else if (isChrome)
      setNavigation(
        'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'
      );
    else if (isOpera)
      setNavigation(
        'https://addons.opera.com/en-gb/extensions/details/metamask-10/'
      );
    // else if(isBrave)
    else
      setNavigation(
        'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'
      );
  };
  const web3Handler = async () => {
    if (
      window.ethereum &&
      window.ethereum.isMetaMask &&
      !window.ethereum.isBraveWallet
    ) {
      try {
        // const x = await handleSwitchNetwork();
        // if (x !== 1) {
        //   alert("Switch network");
        //   return;
        // }

        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        setAccount(accounts[0]);

        // Get provider from Metamask
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(provider);
        // Set signer
        const signer = provider.getSigner();

        window.ethereum.on('chainChanged', (chainId) => {
          // window.location.reload();
        });

        window.ethereum.on('accountsChanged', async function (accounts) {
          setAccount(accounts[0]);
          await web3Handler();
        });
        loadContracts(signer);
      } catch (err) {
        console.log(err);
      }
    } else {
      setMsg(
        "Yo, you ain't got Metamask installed, bro. Gotta get that sh*t first to proceed."
      );
      setIsModalOpen(true);
      findBrowserAndSetNavigation();
      // setButtonText("Install Metamask");
      return;
    }
  };
  const loadContracts = async (signer) => {
    const soRekt = new ethers.Contract(
      SoRektAddress.address,
      SoRektAbi.abi,
      signer
    );
    setSoRekt(soRekt);
    // let symbol = await soRekt.symbol();
    let isWhitelisted = await soRekt.onlyWhitelisted();
    setWhitelisted(isWhitelisted);
  };

  return (
    <>
      <BrowserRouter>
        <main>
          <Routes>
            <Route
              path='/'
              element={
                <HomePage
                  web3Handler={web3Handler}
                  account={account}
                  soRekt={soRekt}
                />
              }
            />
            <Route
              path='/mint-page'
              element={
                <MintPage
                  account={account}
                  provider={provider}
                  soRekt={soRekt}
                  isWhitelisted={isWhitelisted}
                />
              }
            />
            <Route path='/congrats-page' element={<CongPage />} />
            <Route
              path='/indexx-collections/nft'
              element={
                <CollectionsPage
                  account={account}
                  soRekt={soRekt}
                  provider={provider}
                />
              }
            />
            <Route
              path='/indexx-collections/tokens'
              element={
                <CollectionsPage
                  account={account}
                  soRekt={soRekt}
                  provider={provider}
                />
              }
            />
            <Route
              path='/indexx-collections/others'
              element={
                <CollectionsPage
                  account={account}
                  soRekt={soRekt}
                  provider={provider}
                />
              }
            />
          </Routes>
        </main>
      </BrowserRouter>
      <div className='meta-mask-pop'>
        <TilePopUp
          trigger={isModalOpen}
          onClick={() => {
            window.open(navigation);
            setIsModalOpen(false);
          }}
          // text='You do not have metamask installed. Kindly install metamask to proceed.'
          text={msg}
          isIcon={true}
          // for Button
          btnText='Install Metamask'
          btnw='170px'
          btnh='59px'
          txtt='0'
          txtb='0'
          txtr='0'
          txtl='13%'
          txtsz='16.5px'
          txtf="'Avenir',sans-serif"
          txtlh='21px'
          name='installMeta'
          // till here
        />
      </div>
    </>
  );
}

export default App;
