import { sorekt_black } from './Assets/Index';
// import { sorekt, sorekt_black } from './Assets/Index';
import Button from './Button';
import './styles/MintPage.css';
import CONFIG from './Config.json';

import React, { useEffect } from 'react';
// import { ethers } from 'ethers';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import Indexx from './contractsData/Indexx.json';
import IndexxAddress from './contractsData/Indexx-address.json';
import SoRektAddress from './contractsData/SoRekt-address.json';
import TilePopUp from './TilePopUp';
import BackButton from './BackButton';
import { verifyIsWhiteListed } from './api';

const ethers = require('ethers');
const MintPage = ({ account, provider, soRekt, isWhitelisted }) => {
  const state = useLocation();
  // const [nftMetadata, setNftMetadata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [buttonText, setButtonText] = useState('Ok');
  const [navigation, setNavigation] = useState();
  const count = state.state?.count;
  const navigate = useNavigate();
  function extractErrorCode(str) {
    // console.log('in extract', str);
    setNavigation();
    setButtonText('Ok');
    let ans = 'An error occured';
    // let firstOccurence, secondOccurence;
    let strmsg = str.toString();
    if (strmsg.includes('is not a function')) {
      ans = 'Kindly connect your wallet again';
      setButtonText('Go back');
      setNavigation('/');
    } else if (strmsg.includes('Nonce too high')) {
      ans =
        '\t\tNonce is too High\n Reset your acc using: \n settings-> Advanced-> Reset your account';
    } else {
      str = JSON.stringify(str);
      if (str.includes('underlying network changed')) {
        ans = 'Change to Goerli Testnet.';
      } else if (str.includes('transfer amount exceeds balance')) {
        ans =
          'You do not have enough iNEX tokens. Kindly buy iNEX tokens to mint the NFT.';
        setNavigation('https://test.dex.indexx.ai/');
        setButtonText('Buy iNEX Token');
      } else if (str.includes('insufficient allowance')) {
        ans = 'We apologise for the network error. Kindly try again.';
        // setButtonText("Retry");
      } else if (
        str.includes('NetworkError when attempting to fetch resource')
      ) {
        ans = 'Network Error: Goerli Network is unavailable.\n Try again later';
        // setButtonText("Go to Home Page");
        // setNavigation("/");
      } else if (
        str.includes('MetaMask is having trouble connecting to the network')
      ) {
        ans =
          'MetaMask is having trouble connecting to the network.\n Try again later';
        // setButtonText("Go to Home Page");
        // setNavigation("/");
      } else if (str.includes('Over Wallet Limit')) {
        ans = 'You have reached the maximum limit to mint the NFT.';
        setButtonText('Go Back');
        setNavigation('/');
      } else if (str.includes('user rejected transaction')) {
        ans =
          "We are sorry that you had to reject the transaction. Don't miss out on your chance to mint the NFT. Hurry up to mint again";
        // setButtonText("Buy Ticket");
        setButtonText('Mint again');
        // console.log("Navigaiton: ", navigation);
      } else if (str.includes('insufficient funds')) {
        ans =
          'You do not have sufficient balance. Kindly buy ethers (ETH) to proceed';
        setButtonText('Buy Ether (ETH)');
        setNavigation('https://test.dex.indexx.ai/');
      } else {
        var j = JSON.parse(str);
        if (j.reason) {
          var reason = j['reason'].substring(0);
          ans = reason;
        } else {
          ans = 'An error occured. Try again later.';
        }
      }
    }
    setMsg(ans);
    setIsModalOpen(true);
  }
 

  const buildNFTMetadata = async (mintTxn, type) => {
    
    let metadata = [];
    for (let index = type; index < mintTxn.logs.length - 1; index++) {
      // token id
      let tokenId = mintTxn.logs[index].topics[3];
      tokenId = parseInt(tokenId, 16);

      // token uri
      let tokenURI = await soRekt.tokenURI(tokenId);
      tokenURI = 'https://ipfs.io/ipfs/' + tokenURI.substring(7);

      // other information of NFT
      // image URL
      const response = await fetch(tokenURI);
      const metadataOfNft = await response.json();
      let imageUrl =
        'https://ipfs.io/ipfs/' + metadataOfNft.image.toString().substring(7);
      

      // pushing metadata
      metadata.push({
        itemId: metadataOfNft.edition,
        name: metadataOfNft.name,
        description: metadataOfNft.description,
        image: imageUrl,
        index: index + 1 - type,
        etherscan: CONFIG.ETHERSCAN_LINK + '/tx/' + mintTxn.transactionHash,
        opensea:
          CONFIG.MARKETPLACE_LINK +
          '/' +
          CONFIG.CONTRACT_ADDRESS +
          '/' +
          tokenId,
      });
      
    }
    // setNftMetadata(metadata);

    // alert(outputMsg);

    // console.log(nftMetadata);
    navigate('/congrats-page', {
      state: {
        metadata: metadata,
        mintedWith: type,
        count: count,
      },
    });
  };

  const handleMintWithInex = async () => {
    try {
      setIsLoading(true);
      let cost = await soRekt.tokenCost();
      cost = cost.mul(count);
      let isWhitelisted = await soRekt.onlyWhitelisted();
      if (isWhitelisted) {
        const verifyUserWhiteListed = await verifyIsWhiteListed(account)
        if (verifyUserWhiteListed.data) {
          // console.log('User is whitelisted');

          let indexxContract = new ethers.Contract(
            IndexxAddress.address,
            Indexx.abi,
            provider.getSigner()
          );
          let approveTxn = await indexxContract.approve(
            SoRektAddress.address,
            cost
          );
          provider.waitForTransaction(approveTxn.hash).then(async function () {
            try {
              
              // let totalCostWei = String(cost.mul(count));
              let mintTxn = await soRekt.mintWithINEX(count);
              provider
                .waitForTransaction(mintTxn.hash)
                .then(async function (mintTxn) {
                  try {
                    // setMsg('Congratulations!! Successfully Minted NFT');
                    // setButtonText('View NFT');
                    // setIsModalOpen(true);

                    // if (count === 2) {
                    //   let mintingSRTTokens = await mintSRTTokens(account, "200")
                    //   console.log('mintingSRTTokens', mintingSRTTokens)
                    // } else if (count === 3) {
                    //   let mintingSRTTokens = await mintSRTTokens(account, "500")
                    //   console.log('mintingSRTTokens', mintingSRTTokens)
                    // } else if (count === 4) {
                    //   let mintingSRTTokens = await mintSRTTokens(account, "900")
                    //   console.log('mintingSRTTokens', mintingSRTTokens)
                    // } else if (count === 5) {
                    //   let mintingSRTTokens = await mintSRTTokens(account, "1400")
                    //   console.log('mintingSRTTokens', mintingSRTTokens)
                    // }
                    await buildNFTMetadata(mintTxn, 2);
                    setIsLoading(false);
                  } catch (err) {
                    // console.log('inner 2: ', err);
                    // extractErrorCode(err);
                    setMsg(
                      'Congratulations! You have successfully minted the NFT. Go to your collections'
                    );
                    setIsLoading(false);
                    setIsModalOpen(true);
                    // todo
                  }
                  // setIsModalOpen(true);
                });

            } catch (err) {
              console.log('Inner try', err);
              extractErrorCode(err);
              setIsLoading(false);
            }

          });
        } else {
          setMsg('You are not whitelisted to mint NFTs');
          setIsLoading(false);
          setIsModalOpen(true);
          return;
        }
      } else {
        let indexxContract = new ethers.Contract(
          IndexxAddress.address,
          Indexx.abi,
          provider.getSigner()
        );
        let approveTxn = await indexxContract.approve(
          SoRektAddress.address,
          cost
        );
        provider.waitForTransaction(approveTxn.hash).then(async function () {
          try {
            let mintTxn = await soRekt.mintWithINEX(count);
            provider
              .waitForTransaction(mintTxn.hash)
              .then(async function (mintTxn) {
                try {
                  setMsg('Congratulations!! Successfully Minted NFT');
                  setButtonText('View NFT');
                  // setIsModalOpen(true);
                  // if (count === 2) {
                  //   let mintingSRTTokens = await mintSRTTokens(account, "200")
                  //   console.log('mintingSRTTokens', mintingSRTTokens)
                  // } else if (count === 3) {
                  //   let mintingSRTTokens = await mintSRTTokens(account, "500")
                  //   console.log('mintingSRTTokens', mintingSRTTokens)
                  // } else if (count === 4) {
                  //   let mintingSRTTokens = await mintSRTTokens(account, "900")
                  //   console.log('mintingSRTTokens', mintingSRTTokens)
                  // } else if (count === 5) {
                  //   let mintingSRTTokens = await mintSRTTokens(account, "1400")
                  //   console.log('mintingSRTTokens', mintingSRTTokens)
                  // }
                  await buildNFTMetadata(mintTxn, 2);
                  setIsLoading(false);
                } catch (err) {
                  console.log('inner 2: ', err);
                  // extractErrorCode(err);
                  setMsg(
                    'Congratulations! You have successfully minted the NFT. Go to your collections'
                  );
                  setIsLoading(false);
                  // setIsModalOpen(true);
                  // todo
                }
                setIsModalOpen(true);
              });

          } catch (err) {
            console.log('Inner try', err);
            extractErrorCode(err);
            setIsLoading(false);
          }

        });

      }
    } catch (err) {
      console.log('Caught: ', err);
      extractErrorCode(err);
      setIsLoading(false);
    }
  };

  const handleMintWithEthereum = async () => {
    try {
      setIsLoading(true);

      // let msg;
      let cost = await soRekt.cost();

      // let gasLimit = CONFIG.GAS_LIMIT;
      let totalCostWei = String(cost.mul(count));

      let isWhitelisted = await soRekt.onlyWhitelisted();
      if (isWhitelisted) {
        const verifyUserWhiteListed = await verifyIsWhiteListed(account)
        if (verifyUserWhiteListed.data) {
          const mintTxn = await soRekt.mint(count, {
            // cost: String(totalCostWei),
            // gasLimit: String(totalGasLimit),
            // maxPriorityFeePerGas: String(2500000000),
            // maxFeePerGas: String(2941660900),
            // contractAddress: CONFIG.CONTRACT_ADDRESS,
            //   from: account,
            value: totalCostWei,
          });
          provider.waitForTransaction(mintTxn.hash).then(async function (mintTxn) {
            
            await buildNFTMetadata(mintTxn, 0);
            // if (count === 2) {
            //   let mintingSRTTokens = await mintSRTTokens(account, "200")
            //   console.log('mintingSRTTokens', mintingSRTTokens)
            // } else if (count === 3) {
            //   let mintingSRTTokens = await mintSRTTokens(account, "500")
            //   console.log('mintingSRTTokens', mintingSRTTokens)
            // } else if (count === 4) {
            //   let mintingSRTTokens = await mintSRTTokens(account, "900")
            //   console.log('mintingSRTTokens', mintingSRTTokens)
            // } else if (count === 5) {
            //   let mintingSRTTokens = await mintSRTTokens(account, "1400")
            //   console.log('mintingSRTTokens', mintingSRTTokens)
            // }
            setIsLoading(false);
          });
        } else {
          setMsg('You are not whitelisted to mint NFTs');
          setIsLoading(false);
          setIsModalOpen(true);
          return;
        }
      } else {
        const mintTxn = await soRekt.mint(count, {
          // cost: String(totalCostWei),
          // gasLimit: String(totalGasLimit),
          // maxPriorityFeePerGas: String(2500000000),
          // maxFeePerGas: String(2941660900),
          // contractAddress: CONFIG.CONTRACT_ADDRESS,
          //   from: account,
          value: totalCostWei,
        });
        provider.waitForTransaction(mintTxn.hash).then(async function (mintTxn) {
          
          await buildNFTMetadata(mintTxn, 0);
          // if (count === 2) {
          //   let mintingSRTTokens = await mintSRTTokens(account, "200")
          //   console.log('mintingSRTTokens', mintingSRTTokens)
          // } else if (count === 3) {
          //   let mintingSRTTokens = await mintSRTTokens(account, "500")
          //   console.log('mintingSRTTokens', mintingSRTTokens)
          // } else if (count === 4) {
          //   let mintingSRTTokens = await mintSRTTokens(account, "900")
          //   console.log('mintingSRTTokens', mintingSRTTokens)
          // } else if (count === 5) {
          //   let mintingSRTTokens = await mintSRTTokens(account, "1400")
          //   console.log('mintingSRTTokens', mintingSRTTokens)
          // }
          setIsLoading(false);
        });
      }
    } catch (error) {
      console.log(error);
      extractErrorCode(error);
      setIsLoading(false);
    }
  };

  /*
  const mintSRTTokens = async (toAddress, noOfTokens) => {
    // Create provider using Ethereum network endpoint
    const provider = new ethers.providers.JsonRpcProvider('https://goerli.infura.io/v3/847e89cb852c43048f143456f82c32ec');
    // Define contract address and ABI
    const contractAddress = '0x61c7F8ce88855E5Cdb7138Fa0b1Df6947fC47b56';
    const contractAbi = [
      {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "Approval",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "Paused",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "bytes32",
            "name": "role",
            "type": "bytes32"
          },
          {
            "indexed": true,
            "internalType": "bytes32",
            "name": "previousAdminRole",
            "type": "bytes32"
          },
          {
            "indexed": true,
            "internalType": "bytes32",
            "name": "newAdminRole",
            "type": "bytes32"
          }
        ],
        "name": "RoleAdminChanged",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "bytes32",
            "name": "role",
            "type": "bytes32"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "account",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "sender",
            "type": "address"
          }
        ],
        "name": "RoleGranted",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "bytes32",
            "name": "role",
            "type": "bytes32"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "account",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "sender",
            "type": "address"
          }
        ],
        "name": "RoleRevoked",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "Transfer",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "Unpaused",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "DEFAULT_ADMIN_ROLE",
        "outputs": [
          {
            "internalType": "bytes32",
            "name": "",
            "type": "bytes32"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "MINTER_ROLE",
        "outputs": [
          {
            "internalType": "bytes32",
            "name": "",
            "type": "bytes32"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "PAUSER_ROLE",
        "outputs": [
          {
            "internalType": "bytes32",
            "name": "",
            "type": "bytes32"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          }
        ],
        "name": "allowance",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "approve",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "balanceOf",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "burnFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_newFeeAdmin",
            "type": "address"
          }
        ],
        "name": "changeFeeAdmin",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bool",
            "name": "status",
            "type": "bool"
          }
        ],
        "name": "changeFeeStatus",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "decimals",
        "outputs": [
          {
            "internalType": "uint8",
            "name": "",
            "type": "uint8"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "subtractedValue",
            "type": "uint256"
          }
        ],
        "name": "decreaseAllowance",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "feeActive",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "feeAdmin",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bytes32",
            "name": "role",
            "type": "bytes32"
          }
        ],
        "name": "getRoleAdmin",
        "outputs": [
          {
            "internalType": "bytes32",
            "name": "",
            "type": "bytes32"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bytes32",
            "name": "role",
            "type": "bytes32"
          },
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "grantRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bytes32",
            "name": "role",
            "type": "bytes32"
          },
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "hasRole",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "addedValue",
            "type": "uint256"
          }
        ],
        "name": "increaseAllowance",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "name",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "pause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "paused",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bytes32",
            "name": "role",
            "type": "bytes32"
          },
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "renounceRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bytes32",
            "name": "role",
            "type": "bytes32"
          },
          {
            "internalType": "address",
            "name": "account",
            "type": "address"
          }
        ],
        "name": "revokeRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bytes4",
            "name": "interfaceId",
            "type": "bytes4"
          }
        ],
        "name": "supportsInterface",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "symbol",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "transfer",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "transferFrom",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "unpause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      }
    ]; // Replace with your contract ABI
    const privateKey = '0xe8ab253620a32feff72f702a6120c0e74e87578dc5585f719694b1f3d645815c'; // Replace with user's private key
    const wallet = new ethers.Wallet(privateKey, provider);
    // Create contract instance
    const contractInstance = new ethers.Contract(contractAddress, contractAbi, provider, {
      from: wallet.address,
    });
    // Define mint function arguments
    const to = toAddress;
    const amount = ethers.utils.parseEther(noOfTokens); // Convert 1000 ETH to wei
    let baseNonce = provider.getTransactionCount(wallet.getAddress());
    let nonceOffset = 0;
    async function getNonce() {
      return baseNonce.then((nonce) => (nonce + (nonceOffset++)));
    }
    let feeData = await provider.getFeeData()
    // {
    //   gasPrice: { BigNumber: "14184772639" },
    //   lastBaseFeePerGas: { BigNumber: "14038523098" },
    //   maxFeePerGas: { BigNumber: "29577046196" },
    //   maxPriorityFeePerGas: { BigNumber: "1500000000" }
    // }
    // ...often these values are easier to understand or
    // display to the user in gwei
    let gasPrice = ethers.utils.formatUnits(feeData.maxFeePerGas, "gwei")
    console.log(gasPrice)
    // '29.577046196'
    // Create transaction object
    const transactionObject = {
      nonce: await getNonce(),
      from: wallet.address,
      gasLimit: 2000000, // Replace with your desired gas limit
      to: contractAddress,
      gasPrice: ethers.utils.parseUnits(gasPrice, 'gwei'), // Replace with your desired gas price
      data: contractInstance.interface.encodeFunctionData('mint', [to, amount])
    };
    // Estimate gas required for the transaction
    // const estimatedGas = await contractInstance.estimateGas.mint(to, amount);
    // console.log('Estimated gas:', estimatedGas)
    // Set gas limit for the transaction object
    //transactionObject.gasLimit = estimatedGas;
    // Sign transaction with private key
    const signedTransaction = await wallet.signTransaction(transactionObject);
    // Send signed transaction to the network
    const sentTransaction = await provider.sendTransaction(signedTransaction);
    console.log('Transaction sent:', sentTransaction.hash);
    return sentTransaction.hash;
  }
  const updateAirDropCount = async (mintValue) => {
    // Create provider using Ethereum network endpoint
    const provider = new ethers.providers.JsonRpcProvider('https://goerli.infura.io/v3/847e89cb852c43048f143456f82c32ec');
    // Define contract address and ABI
    const contractAddress = '0x61c7F8ce88855E5Cdb7138Fa0b1Df6947fC47b56';
    const contractAbi = [
      {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "approved",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "Approval",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "operator",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "bool",
            "name": "approved",
            "type": "bool"
          }
        ],
        "name": "ApprovalForAll",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "previousOwner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "Transfer",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "GetAllowance",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "addressMintedBalance",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "airDropPerAdressLimit",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "tokenHolder",
            "type": "address"
          }
        ],
        "name": "awardItem",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          }
        ],
        "name": "balanceOf",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "cost",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "getApproved",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "hiddenMetadataUri",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "operator",
            "type": "address"
          }
        ],
        "name": "isApprovedForAll",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_user",
            "type": "address"
          }
        ],
        "name": "isWhitelisted",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "maxMintAmountPerTx",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "maxSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_mintAmount",
            "type": "uint256"
          }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_mintAmount",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_receiver",
            "type": "address"
          }
        ],
        "name": "mintForAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_mintAmount",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "_receiver",
            "type": "address"
          }
        ],
        "name": "mintForAddressCompany",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_mintAmount",
            "type": "uint256"
          }
        ],
        "name": "mintWithINEX",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "name",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "nftPerAdressLimit",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "onlyWhitelisted",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "ownerOf",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "paused",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "revealed",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          },
          {
            "internalType": "bytes",
            "name": "data",
            "type": "bytes"
          }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "operator",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "approved",
            "type": "bool"
          }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_cost",
            "type": "uint256"
          }
        ],
        "name": "setCost",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "string",
            "name": "_hiddenMetadataUri",
            "type": "string"
          }
        ],
        "name": "setHiddenMetadataUri",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_maxMintAmountPerTx",
            "type": "uint256"
          }
        ],
        "name": "setMaxMintAmountPerTx",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_limit",
            "type": "uint256"
          }
        ],
        "name": "setNftPerAddressLimit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bool",
            "name": "_state",
            "type": "bool"
          }
        ],
        "name": "setOnlyWhitelisted",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bool",
            "name": "_state",
            "type": "bool"
          }
        ],
        "name": "setPaused",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bool",
            "name": "_state",
            "type": "bool"
          }
        ],
        "name": "setRevealed",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_tokenAddress",
            "type": "address"
          }
        ],
        "name": "setTokenAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_tokenCost",
            "type": "uint256"
          }
        ],
        "name": "setTokenCost",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "string",
            "name": "_uriPrefix",
            "type": "string"
          }
        ],
        "name": "setUriPrefix",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "string",
            "name": "_uriSuffix",
            "type": "string"
          }
        ],
        "name": "setUriSuffix",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_limit",
            "type": "uint256"
          }
        ],
        "name": "setairdropPerAddressLimit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "sorektAirDrop",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_tokenCount",
            "type": "uint256"
          }
        ],
        "name": "sorektAirDropCount",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "bytes4",
            "name": "interfaceId",
            "type": "bytes4"
          }
        ],
        "name": "supportsInterface",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "symbol",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "tokenCost",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_tokenId",
            "type": "uint256"
          }
        ],
        "name": "tokenURI",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "newOwner",
            "type": "address"
          }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "uriPrefix",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "uriSuffix",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_owner",
            "type": "address"
          }
        ],
        "name": "walletOfOwner",
        "outputs": [
          {
            "internalType": "uint256[]",
            "name": "",
            "type": "uint256[]"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address[]",
            "name": "_users",
            "type": "address[]"
          }
        ],
        "name": "whitelistUsers",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "name": "whitelistedAddresses",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "withdrawToken",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      }
    ]; // Replace with your contract ABI
    const privateKey = '0x475d684b486e10bacd57dc67ac6b0ab566ab0b3235c1acda07fa3bb3bb8eb425'; // Replace with user's private key
    const wallet = new ethers.Wallet(privateKey, provider);
    // Create contract instance
    const contractInstance = new ethers.Contract(contractAddress, contractAbi, provider, {
      from: wallet.address,
    });
    // Define mint function arguments
    const amount = ethers.utils.parseEther(mintValue); // Convert 1000 ETH to wei
    let baseNonce = provider.getTransactionCount(wallet.getAddress());
    let nonceOffset = 0;
    async function getNonce() {
      return baseNonce.then((nonce) => (nonce + (nonceOffset++)));
    }
    let feeData = await provider.getFeeData()
    // {
    //   gasPrice: { BigNumber: "14184772639" },
    //   lastBaseFeePerGas: { BigNumber: "14038523098" },
    //   maxFeePerGas: { BigNumber: "29577046196" },
    //   maxPriorityFeePerGas: { BigNumber: "1500000000" }
    // }
    // ...often these values are easier to understand or
    console.log(feeData)
    // display to the user in gwei
    let gasPrice = ethers.utils.formatUnits(feeData.maxFeePerGas, "gwei")
    console.log(gasPrice)
    // '29.577046196'
    // Create transaction object
    const transactionObject = {
      nonce: await getNonce(),
      from: wallet.address,
      gasLimit: 2000000, // Replace with your desired gas limit
      to: contractAddress,
      gasPrice: ethers.utils.parseUnits(gasPrice, 'gwei'), // Replace with your desired gas price
      data: contractInstance.interface.encodeFunctionData('sorektAirDropCount', [amount])
    };
    // Estimate gas required for the transaction
    const estimatedGas = await contractInstance.estimateGas.sorektAirDropCount({
      from: wallet.address,
    }, amount);
    console.log('Estimated gas:', estimatedGas)
    // Set gas limit for the transaction object
    transactionObject.gasLimit = estimatedGas;
    // Sign transaction with private key
    const signedTransaction = await wallet.signTransaction(transactionObject);
    // Send signed transaction to the network
    const sentTransaction = await provider.sendTransaction(signedTransaction);
    console.log('Transaction sent:', sentTransaction.hash);
    return sentTransaction.hash;
  } */
  useEffect(() => {
    if (!account || !soRekt.signer) {
      navigate("/");

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, soRekt]);

  return (
    <div className='mint-page'>
      <BackButton
        btnh='5%'
        btnw='2.5%'
        btnTp='2.5%'
        btnBtm='3.8%'
        name='mint_1'
        link='/'
      />
      <div className='mint-page_content'>
        <div>
          <img
            className='mint-page_content_sorekt'
            src={sorekt_black}
            alt='sorekt'
          />
        </div>
        <div className='mint-page_content_nft'>
          <p className='mint-page_content_nft_txt'>NFT</p>
        </div>
        <div className='mint-page_buttons'>
          <div>
            <Button
              disabled={isLoading}
              onClick={handleMintWithEthereum}
              txt='Mint'
              btnw='198px'
              btnh='70px'
              txtt='10px'
              txtb='0'
              txtr='0'
              txtl='79px'
              txtsz='16.5px'
              txtf="'Avenir',sans-serif"
              name='mint1'
            />
          </div>
          {!isWhitelisted &&
            <div>
              <Button
                disabled={isLoading}
                onClick={handleMintWithInex}
                txt='Mint with discount'
                btnw='198px'
                btnh='70px'
                txtt='10px'
                txtb='0'
                txtr='0'
                txtl='40px'
                txtsz='16.5px'
                txtf="'Avenir',sans-serif"
                name='mint2'
              />
            </div>
          }
        </div>
        <div className='mint-page_desc'>
          <p className='mint-page_desc_txt'>Buy SoRekt NFT with Ether</p>
          {!isWhitelisted &&
            <p className='mint-page_desc_txt'>Buy SoRekt NFT with INEX</p>
          }
        </div>
        <p className='mint-page__contents__rights'>All Rights Reserved 2023</p>
      </div>
      {/* <img src={image} alt="NFT_Image" /> */}
      <div className='meta-mask-pop'>
        <TilePopUp
          trigger={isModalOpen}
          // onClick={() => isModalOpen(false)}
          onClick={() => {
            if (navigation && navigation.includes('http'))
              window.open(navigation);
            else if (navigation) window.location.replace(navigation);
            setIsModalOpen(false);
          }}
          text={msg}
          backButton={
            msg ===
            'You do not have sufficient balance. Kindly buy ethers (ETH) to proceed'
          }
          backButtonIdName='tileBackButton'
          onBackButtonClick={() => {
            setIsModalOpen(false);
          }}
          // for Button
          btnText={buttonText}
          btnw='170px'
          btnh='59px'
          txtt='0px'
          txtb='0'
          txtr='0'
          txtl='40%'
          txtsz='16.5px'
          txtf="'Avenir',sans-serif"
          txtlh='21px'
          name='contractValidation'
        // till here
        />
      </div>
    </div>
  );
};

export default MintPage;