import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  nft_collections,
  nft_dark_collections,
  others_collections,
  others_dark_collections,
  tokens_collections,
  tokens_dark_collections,
} from './Assets/Index';
import './styles/CollectionsNavBar.css';

const CollectionsNavBar = (props) => {
  return (
    <div className='nav-bar'>
      <div onClick={props.onClickNft} className='nav-bar_icons'>
        <NavLink to='/indexx-collections/nft'>
          <img src={nft_collections} alt='nft_collections' />
          <img
            className='nav-bar_icons_hover'
            src={nft_dark_collections}
            alt='nft_dark_collections'
          />
        </NavLink>
      </div>
      <div onClick={props.onClickToken} className='nav-bar_icons'>
        <NavLink to='/indexx-collections/tokens'>
          <img src={tokens_collections} alt='tokens_collections' />
          <img
            className='nav-bar_icons_hover'
            src={tokens_dark_collections}
            alt='tokens_dark_collections'
          />
        </NavLink>
      </div>
      <div onClick={props.onClickOthers} className='nav-bar_icons'>
        <NavLink to='/indexx-collections/others'>
          <img src={others_collections} alt='others_collections' />
          <img
            className='nav-bar_icons_hover'
            src={others_dark_collections}
            alt='others_dark_collections'
          />
        </NavLink>
      </div>
    </div>
  );
};

export default CollectionsNavBar;
