import { useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';

import { gif, minus, plus, sorekt, circle } from './Assets/Index';
import Button from './Button';
import './styles/HomePage.css';
import TilePopUp from './TilePopUp';
import BackButton from './BackButton';
// import { NavLink } from "react-router-dom";
// import BrokenTilePopUp from './BrokenTilePopUp';

const HomePage = ({ web3Handler, account, soRekt }) => {
  // let walletConnected = true;
  // const [metaMaskPop, setMetaMaskPop] = useState(false);
  // const [indexWalletPop, setIndexWalletPop] = useState(false);
  let button;
  const [count, setCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [buttonText, setButtonText] = useState('Choose Again');
  const navigate = useNavigate();
  const handleMint = async () => {
    if (count <= 0) {
      setMsg("You can't mint negative number. Kindly enter positive number");
      setButtonText('Choose Again');

      setIsModalOpen(true);
      return;
    }
    try {
      let limit = await soRekt.maxMintAmountPerTx();
      limit = parseInt(limit.toString(), 10);
      if (count > limit) {
        setMsg(
          'You can mint maximum of ' +
            limit +
            ' NFTs at a time. Kindly reduce the count and mint.'
        );
        setIsModalOpen(true);
        setButtonText('Choose Again');
        return;
      }
    } catch (error) {
      console.log(error);
    }
    navigate('/mint-page', {
      state: {
        count: count,
      },
    });
  };

  function windowRefresh() {
    window.location.reload(false);
  }
  const handleViewCollection = () => {
    if (!account) {
      setMsg('Kindly Connect your Wallet to view your collection.');
      setIsModalOpen(true);
      setButtonText('Ok');
      return;
    }
    navigate('/indexx-collections/nft');
  };

  // function onConnectWallet() {
  //   setMetaMaskPop(true);
  //   // reduceOpacity();
  //   web3Handler();
  // }

  // function reduceOpacity() {
  //   document.getElementById('appContent').style.opacity = 0.2;
  // }

  // function increaseOpacity() {
  //   document.getElementById('appContent').style.opacity = 1;
  // }

  // if (connectWalletPop) {
  //   popUp = (
  //     <div className='connect-wallet-pops'>
  //       <BrokenTilePopUp
  //         ht='50%'
  //         wd='50%'
  //         trigger={connectWalletPop}
  //         text='You do not have IndexxWallet installed. Kindly install IndexxWallet to proceed.'
  //         btnText='Install IndexxWallet'
  //         btnw='190px'
  //         btnh='60px'
  //         txtt='0px'
  //         txtb='0'
  //         txtr='0'
  //         txtl='22px'
  //         txtsz='16.5px'
  //         txtf="'Avenir',sans-serif"
  //         txtlh='21px'
  //         name='installIndex'
  //       />
  //     </div>
  //   );
  // } else {
  //   popUp = '';
  // }
  if (!soRekt.signer || !account) {
    button = (
      <div>
        <BackButton
          btnh='80px'
          btnw='76.54px'
          btnTp='1%'
          btnBtm='4.5%'
          name='mint_1'
          link='https://sorekt.wtf/'
        />
        <div className='connect-wallet'>
          <Button
            onClick={web3Handler}
            txt='Connect Wallet'
            btnw='170px'
            btnh='59px'
            txtt='0px'
            txtb='0'
            txtr='0'
            txtl='30px'
            txtsz='16.5px'
            txtf="'Avenir',sans-serif"
            txtlh='21px'
          />
        </div>
      </div>
    );
  } else {
    button = (
      <div className='minting'>
        <BackButton
          btnh='80px'
          btnw='76.54px'
          name='mint_1'
          btnTp='1%'
          btnBtm='4.5%'
          link='/'
          onClick={windowRefresh}
        />
        <div className='plus-minus'>
          <img
            className='App__content__circle-plus'
            src={circle}
            alt='circle'
            onClick={() => setCount(count + 1)}
          />
          <img
            className='App__content__plus'
            src={plus}
            alt='plus'
            onClick={() => setCount(count + 1)}
          />
          <div className='App__content__num'>
            <pc>{count}</pc>
          </div>
          <img
            className='App__content__circle-minus'
            src={circle}
            alt='circle'
            onClick={() => setCount(count - 1)}
          />
          <img
            className='App__content__minus'
            src={minus}
            alt='minus'
            onClick={() => setCount(count - 1)}
          />
        </div>
        <div className='App__content__mint'>
          <Button
            onClick={handleMint}
            txt='Mint'
            btnw='170px'
            btnh='59px'
            txtt='0'
            txb='0'
            txtr='0'
            txtl='68px'
            txtsz='16.5px'
            txtf="'Avenir',sans-serif"
            txtlh='21px'
          />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div id='appContent' className='App'>
        <div className='App__content'>
          <div className='App__content__div'>
            <img className='App__content__gif' src={gif} alt='gif' />
          </div>
          <div>
            <p className='App__content__unique'>Mint your own unique</p>
          </div>
          <div>
            <img className='App__content__sorekt' src={sorekt} alt='sorekt' />
          </div>
          <div>
            <p className='App__content__nft'>NFT & Token</p>
          </div>
          {button}
        </div>
        <div className='App_content_btn_div'>
          {/* <NavLink to='/indexx-collections/nft'> */}
          <button
            onClick={handleViewCollection}
            id='collectionBtn'
            className='App_content_btn'
          >
            View my collection
          </button>
          {/* </NavLink> */}
        </div>
      </div>
      <div className='meta-mask-pop'>
        <TilePopUp
          trigger={isModalOpen}
          // onClick={() => isModalOpen(false)}
          onClick={() => {
            setIsModalOpen(false);
          }}
          text={msg}
          // for Button
          btnText={buttonText}
          btnw='170px'
          btnh='59px'
          txtt='0'
          txtb='0'
          txtr='0'
          txtl='16%'
          txtsz='16.5px'
          txtf="'Avenir',sans-serif"
          txtlh='21px'
          name='mintValidation'
          // till here
        />
      </div>
      {/* <div className='index-wallet-pop'>
        <BrokenTilePopUp
          trigger={indexWalletPop}
          // onClick={() => setIsModalOpen(false)}
          text='You do not have metamask installed. Kindly install metamask to proceed.'
          // for Button
          btnText='Install Indexx Wallet'
          btnw='190px'
          btnh='65px'
          txtt='5%'
          txtb='0'
          txtr='0'
          txtl='22px'
          txtsz='16.5px'
          txtf="'Avenir',sans-serif"
          txtlh='21px'
          name='installIndex'
        // till here
        />
      </div> */}
    </div>
  );
};

export default HomePage;
